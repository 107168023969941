import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { DateAndTimeService } from '@shared/services/date-and-time.service';
import { DateTime } from 'luxon';
import { DispositionAuthResource } from '@data/disposition-auth/disposition-auth.model';

@Component({
  selector: 'app-authorization-handlling-cell-date',
  templateUrl: './authorization-handlling-cell-date.component.html',
  styleUrls: ['./authorization-handlling-cell-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationHandllingCellDateComponent implements OnInit {
  @Input() auths: DispositionAuthResource[];
  @HostBinding('class.success') isSuccess = false;
  iconColor = '';
  time = '';
  roles: Record<string, DispositionAuthResource> = {};
  auth: DispositionAuthResource;

  constructor(private dateAndTimeService: DateAndTimeService) {}
  ngOnInit(): void {
    this.populateRoles();
    this.auth = this.roles['pso'] ? this.roles['pso'] : this.roles['fla'];
    this.dateToTimeCovertion(this.auth?.authorized_at);
  }

  private populateRoles() {
    this.auths
      .filter((auth) => auth.is_auth_required)
      .map((auth) => {
        this.roles[auth?.auth_group] = auth;
      });
  }

  private dateToTimeCovertion(_date: string): void {
    if (!_date) {
      return;
    }
    const date = DateTime.fromISO(_date);
    const timeBetweenTwoDates = date.diffNow('days').days;
    if (date.diffNow('days').days > -4 && date.diffNow('days').days < -1) {
      this.time = this.dateAndTimeService.displayTimeAgo(timeBetweenTwoDates);
      this.iconColor = 'yellow';
    } else if (date.diffNow('days').days > -1) {
      this.time = this.dateAndTimeService.displayTimeAgo(timeBetweenTwoDates);
      this.iconColor = '';
    } else {
      this.time = date.toFormat('dd.MM.yyyy');
      this.iconColor = 'red';
    }
  }
}
