import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from './select-item';
import { SelectionModel } from '@angular/cdk/collections';
import { BaseSelectComponent } from './base-select.component';
import { SelectDropdownService } from '../dropdown/select-dropdown.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base-multi-select',
  templateUrl: './base-multi-select.component.html',
  styleUrls: ['./base-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseMultiSelectComponent),
      multi: true,
    },
    SelectDropdownService,
  ],
})
export class BaseMultiSelectComponent extends BaseSelectComponent implements ControlValueAccessor {
  @Input() placeholder = this.translateService.instant('select');
  selectionModel = new SelectionModel<SelectItem<unknown>>(true);
  value: any[];

  writeValue(value: unknown): void | undefined {
    if (value && !Array.isArray(value)) {
      value = [value];
    }

    if (Array.isArray(value) && value.length > 0) {
      const items = getOnlyExistingItems(value as [], this.items);
      if (items.length > 0) {
        this.displayValue = items[0].getLabel();
        this.selectionModel.clear();
        this.selectionModel.select(...items);
        this.value = this.selectionModel.selected.map((item: SelectItem<unknown>) => item.getValue());
        this.onChangeFn(this.value);
        this.changeDetectorRef.detectChanges();
        return;
      }
    }

    this.displayValue = '';
    this.value = [];
    this.selectionModel.clear();
    this.onChangeFn(this.value);
    this.changeDetectorRef.detectChanges();
  }

  openDropdown(): void {
    super.openDropdown((item) => {
      this.onItemClick(item);
    });
  }

  onItemRemove(event: any, item: SelectItem<unknown>): void {
    this.selectionModel.deselect(item);
    this.writeValue(this.selectionModel.selected.map((item: SelectItem<unknown>) => item.getValue()));
  }

  onXIconClick(): void {
    this.writeValue(null);
  }

  onItemClick(item: SelectItem<unknown>): void {
    if (this.selectionModel.isSelected(item)) {
      this.onItemRemove(undefined, item);
    } else {
      this.selectionModel.select(item);
      this.writeValue(this.selectionModel.selected.map((item: SelectItem<unknown>) => item.getValue()));
    }
  }

  onSelectAllClick(): void {
    this.selectionModel.select(...this.items);
    this.writeValue(this.selectionModel.selected.map((item: SelectItem<unknown>) => item.getValue()));
  }
}

const getOnlyExistingItems = (arr: [], items: SelectItem<unknown>[]) => {
  return items.filter((item: SelectItem<unknown>) => {
    let has = false;
    arr.forEach((val: unknown) => {
      if (!has) {
        has = item.is(val);
      }
    });
    return has;
  });
};
