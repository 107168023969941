import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DispositionAuthResource } from '@data/disposition-auth/disposition-auth.model';

@Component({
  selector: 'app-authorization-handlling-cell-user',
  templateUrl: './authorization-handlling-cell-user.component.html',
  styleUrls: ['./authorization-handlling-cell-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationHandllingCellUserComponent implements OnInit {
  @Input() auths: DispositionAuthResource[];
  roles: Record<string, string> = { pso: '', fla: '' };
  user: string;
  ngOnInit(): void {
    this.displayAuthUser();
    this.user = this.roles['pso'] ? this.roles['pso'] : this.roles['fla'];
  }

  private displayAuthUser(): void {
    this.auths
      .filter((auth) => auth.is_auth_required)
      .map((auth) => {
        const firstName = auth?.user?.first_name || '';
        const lastName = auth?.user?.last_name || '';
        this.roles[auth?.auth_group] = firstName + ' ' + lastName;
      });
  }
}
